import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import PlaceholderImage from "./PlaceholderImage"
import ConvertTimestamp from "./ConvertTimestamp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"
import BadgeCategory from "./BadgeCategory"

export default function ArchiveArticle({ article, i }) {
  const { abstract, category, img, release, slug, title } = article.frontmatter

  const index = i + 2 // Set each `Card.Title` as <h{i}>... Starting at `h2` since the `h1` is the page title!
  const image = getImage(img)

  return (
    <Row className="my-4 my-xl-5">
      <Col className="order-1 order-lg-0">
        <Card className="border-0" as="article">
          <p className="text-muted small mt-3 mt-lg-0 mb-3">
            <ConvertTimestamp date={release} />
          </p>
          <Card.Title as={`h${index}`} className="h2 font-weight-bold">
            <Link className="text-decoration-none" to={`/archiv/${slug}`}>
              {title}
            </Link>
          </Card.Title>
          <p className="lh-text text-body mb-4">{abstract}</p>
        </Card>
      </Col>
      <Col lg={4}>
        <div className="position-relative">
          <div
            className="position-absolute h5"
            style={{
              top: "0.5rem",
              right: "0.75rem",
              zIndex: 1000,
            }}
          >
            <BadgeCategory category={category} />
          </div>
          <Link to={`/archiv/${slug}`}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                className="img-fluid rounded"
                image={getImage(image)}
                alt={abstract}
              />
            ) : (
              <PlaceholderImage />
            )}
          </Link>
        </div>
      </Col>
    </Row>
  )
}
