import React from "react"
import { graphql, Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Pagination from "react-bootstrap/Pagination"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ArchiveArticle from "../components/ArchiveArticle"
import Seo from "../components/Seo"

export const Head = () => {
  return <Seo customTitle="Archiv" />
}

export default function Archiv({ data }) {
  const articles = data.allMarkdownRemark.edges

  // `/ 5` because in the `gatsby-node.js` 5 is the postsPerPage!
  const perPage = 5
  const pages = Math.ceil(data.allMarkdownRemark.totalCount / perPage)

  const pageList = []

  for (let index = 1; index <= pages; index++) {
    pageList.push(
      <Pagination.Item key={index} as="div">
        {index === 1 ? (
          <Link to={`/archiv/`} key={index}>
            {index}
          </Link>
        ) : (
          <Link to={`/archiv/${index}`} key={index}>
            {index}
          </Link>
        )}
      </Pagination.Item>,
    )
  }

  // Remove `href` attribute from `div.page-link` since the link is nested
  // inside the `div`
  //
  // E.g.
  //
  // <div class="page-link" role="button" href="#"> <!-- Remove `href` here
  //   <a href="/archiv/2">2</a>
  // </div>

  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    const elementPageLink = document.querySelectorAll(".page-link")

    elementPageLink.forEach(element => {
      element.removeAttribute("href")
    })
  }

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Archiv" />
        {articles.map(({ node }, index) => (
          <div key={index}>
            <ArchiveArticle
              article={node}
              i={index}
              pages={pages}
              perPage={perPage}
            />
            {articles.length - 1 !== index ? <hr /> : null}
          </div>
        ))}
        <Pagination className="justify-content-center" as="div">
          <Pagination.First as="div">
            <Link to="/archiv/">&laquo;</Link>
          </Pagination.First>
          {pageList}
          <Pagination.Last as="div">
            <Link to={`/archiv/${pages}`}>&raquo;</Link>
          </Pagination.Last>
        </Pagination>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query archive($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___release, order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            release
            abstract
            category
            img {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
